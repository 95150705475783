<template>
  <v-card
    min-width="100"
    height="180px"
    outlined
    flat
    @click="seeUnitDetails()"
  >
    <div
      class="d-flex fill-height flex-column justify-space-between"
    >
      <v-alert
        v-if="!isLoadingPlayers"
        class="mb-0"
        dense
        :type="alertStatus"
      >
        {{ alertMessage }}
      </v-alert>
      <v-skeleton-loader
        v-else
        type="list-item"
      />

      <div v-if="!isLoadingUnit">
        <v-card-title class="justify-center py-0">
          <v-tooltip
            bottom
            left
            open-delay="600"
            open-on-hover
          >
            <template v-slot:activator="{ on, attrs }">
              <strong
                class="text-truncate subtitle-1 text-sm-h6 font-weight-bold textBlack--text"
                data-dd-privacy="mask"
                v-bind="attrs"
                v-on="on"
              >
                {{ unitName }}
              </strong>
            </template>
            <span data-dd-privacy="mask">{{ unitName }}</span>
          </v-tooltip>
        </v-card-title>
        <div
          class="d-flex flex-wrap mx-3"
          style="display: flex; flex: 0;"
        >
          <tag
            v-for="tag in previewTags"
            :key="tag.id"
            class="pa-1"
            small
            :tag-id="tag.id"
          />

          <div
            v-if="unit.tags.length > 2"
            class="d-flex flex-column justify-end ml-2"
            style="flex: 1"
          >
            <v-menu
              :close-on-content-click="false"
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="neutral"
                  small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    {{ icon.tag }}
                  </v-icon>
                </v-btn>
              </template>
              <v-card max-width="300">
                <v-card-title>
                  {{ $t('deconve.tags') }}
                </v-card-title>
                <v-card-text>
                  <div class="d-flex flex-wrap">
                    <tag
                      v-for="tag in unit.tags"
                      :key="tag.id"
                      class="pa-1"
                      :tag-id="tag.id"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
        </div>
      </div>

      <v-skeleton-loader
        v-else
        type="list-item-three-line"
      />

      <v-card-actions v-if="!isLoadingPlayers">
        <div class="d-flex align-center mx-1">
          <v-icon
            :color="numberOfPlayersOffline > 0 ? 'error' : 'neutral'"
            left
            :small="$vuetify.breakpoint.mobile"
          >
            {{ icon.calendar }}
          </v-icon>
          <span
            v-if="lastLogAt"
            class="caption text-sm-body-2"
            :class="numberOfPlayersOffline > 0 ? 'error--text' : 'neutral--text'"
          >
            {{ lastLogAt }}
          </span>
        </div>
        <v-spacer />
        <div class="d-flex align-center mx-1">
          <span
            v-if="lastLogAt"
            class="caption text-sm-body-2"
            :class="numberOfPlayersOffline > 0 ? 'error--text' : 'neutral--text'"
          >
            {{ numberOfPlayers }}
          </span>
          <v-icon
            :color="numberOfPlayersOffline > 0 ? 'error' : 'neutral'"
            right
            :small="$vuetify.breakpoint.mobile"
          >
            {{ icon.players }}
          </v-icon>
        </div>
      </v-card-actions>
      <v-skeleton-loader
        v-else
        type="card-heading"
      />
    </div>
  </v-card>
</template>

<script>
// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { mapActions } from 'vuex';
import { mdiPlayBoxMultiple, mdiUpdate, mdiTagMultipleOutline } from '@mdi/js';

import Tag from '@/components/Tag.vue';
import { PLAYER_STATUS } from '@/store/players';

export default {
  name: 'UnitPreview',
  components: {
    Tag,
  },
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      isLoadingUnit: true,
      isLoadingPlayers: true,
      numberOfPlayersRunning: 0,
      numberOfPlayersStopped: 0,
      numberOfPlayersOffline: 0,
      players: null,
      unit: null,
      icon: {
        calendar: mdiUpdate,
        players: mdiPlayBoxMultiple,
        tag: mdiTagMultipleOutline,
      },
    };
  },
  computed: {
    unitName() {
      return this.unit?.name || '';
    },
    previewTags() {
      return this.unit.tags.slice(0, 2);
    },
    lastLogAt() {
      if (this.players?.length > 0) {
        let newestLogReceivedAt;

        this.players.forEach((player) => {
          if (player?.enabled && player?.last_log) {
            const { received_at: receivedAt } = player.last_log;

            if (!newestLogReceivedAt || receivedAt > newestLogReceivedAt) {
              newestLogReceivedAt = receivedAt;
            }
          }
        });

        if (newestLogReceivedAt) {
          return this.$moment(newestLogReceivedAt).format('DD/MM/YYYY HH:mm:ss');
        }
      }

      return '';
    },
    numberOfPlayers() {
      return this.players?.filter((p) => p?.enabled).length || 0;
    },
    alertMessage() {
      if (this.numberOfPlayersOffline > 0) {
        return this.$t('deconve.playerOffline');
      }

      if (this.numberOfPlayersStopped > 0) {
        return this.$t('deconve.playerStopped');
      }

      return '';
    },
    alertStatus() {
      if (this.numberOfPlayersOffline > 0 || this.numberOfPlayersStopped > 0) {
        return 'error';
      }

      return 'success';
    },
  },
  created() {
    this.fetchUnit(this.id).then((unit) => {
      this.unit = unit;
      this.isLoadingUnit = false;
    }).catch(() => {
      this.isLoadingUnit = false;
    });

    this.fetchCurrentPlayers(this.id).then((players) => {
      this.players = players;
      this.isLoadingPlayers = false;
      this.countPlayerStatus();
    }).catch(() => {
      this.isLoadingPlayers = false;
    });
  },
  methods: {
    ...mapActions({
      fetchUnit: 'units/fetchUnit',
      fetchCurrentPlayers: 'players/fetchCurrentPlayers',
    }),
    seeUnitDetails() {
      this.$router.push({ name: 'unit-details', params: { unitId: this.id } });
    },
    countPlayerStatus() {
      let numberOfPlayersOffline = 0;
      let numberOfPlayersStopped = 0;
      let numberOfPlayersRunning = 0;

      if (this.players) {
        this.players.forEach((player) => {
          const { status } = player.last_log;

          const logId = status?.id || null;

          switch (logId) {
            case PLAYER_STATUS.offline:
              numberOfPlayersOffline += 1;
              break;
            case PLAYER_STATUS.stopped:
              numberOfPlayersStopped += 1;
              break;
            case PLAYER_STATUS.running:
              numberOfPlayersRunning += 1;
              break;
            default:
              break;
          }
        });
      }

      this.numberOfPlayersOffline = numberOfPlayersOffline;
      this.numberOfPlayersStopped = numberOfPlayersStopped;
      this.numberOfPlayersRunning = numberOfPlayersRunning;
    },
  },
};
</script>

<style scoped>
.v-alert {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
</style>
